/* CSS reset */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    /* font: inherit; */
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* My styles */

html {
    font-size: 16px;
    font-family: Roboto;
}

body {
    background-color: rgba(14, 22, 31, 0.9);
    color: white;
}

#campaign-select-wrapper {
    position: relative;
}

/* Use the following code to make the map box and notes appear next to each other, however this results in the map box not functioning correctly - so have taken this away for now */
.map-screen-wrapper {
    width: 100vw;
    height: 90vh;
    display: grid;
    grid-template-rows: 10% 90%;
    grid-template-columns: 70% 30%;
    grid-template-areas: "mapbox journal";
    overflow-y: hidden;
}

/* Add styles for all text inputs */
input[type="text"],
input[type="password"],
input[type="email"] {
    padding: 0.5rem 1rem;
    box-sizing: border-box;
}

/* remove outline on focused inputs */
input:focus,
select:focus,
textarea:focus {
    outline: none;
}
