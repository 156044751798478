#loading-screen {
    width: 70vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    position: absolute;
}

#loading-screen > h1 {
    font-size: 3em;
}

.glowing-circle {
    width: 19rem;
    height: 19rem;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    top: 14.5rem;
    background-color: #fff;
    -webkit-animation: glowing 1s ease-in-out infinite alternate;
    -moz-animation: glowing 1s ease-in-out infinite alternate;
    animation: glowing 1s ease-in-out infinite alternate;
}

@keyframes glowing {
    from {
        box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #f0f, 0 0 40px #0ff,
            0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
        box-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
            0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6,
            0 0 80px #ff4da6;
    }
}
