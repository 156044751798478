#campaign-error-element-wrapper {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#campaign-error-element-wrapper > h2 {
    font-size: 2rem;
}

#campaign-error-element-wrapper > ul {
    list-style-type: disc;
    text-align: left;
}
