/* Journal styles - things to do with Journal.jsx */
.journal {
    width: 100%;
    height: 100vh;
    background-color: rgba(14, 22, 31, 0.5);
    color: white;
    grid-area: journal;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

#journal-front-page-container {
    width: 30vw;
    /* display: grid;
    grid-template-rows: 1fr 2fr;
    grid-template-areas: "menu"; */
    align-items: center;
    justify-content: center;
    /* gap: 2rem; */
    user-select: none;
}

.login-status {
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.4rem;
    grid-area: login;
}

/* .login-status > p {
    text-justify: inter-word;
} */

#seros-logo {
    height: 35vh;
    width: auto;
}

#journal-front-page-menu {
    grid-area: menu;
    align-self: flex-start;
    display: flex;
    position: relative;
    flex-direction: column;
    height: 90vh; /* Had issues with the previous value of 100% causing the last few entries to not appear on the screen when the user scrolled to the bottom */
    width: 30vw;
    overflow-y: auto;
}

#journal-front-page-menu::-webkit-scrollbar {
    display: none;
}

#journal-front-page-menu-header {
    display: flex;
    width: 100%;
}

.journal-menu-wrapper {
    width: 100%;
}
