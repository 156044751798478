#changelog-wrapper {
    display: flex;
    flex-direction: column;
    position: static;
}

.changelog-div {
    /* color: red; */
    margin: 0.5rem 0.5rem;
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 0.5rem;
    background-color: rgba(14, 22, 31, 0.5);
    transition: transform 0.1s linear;
}

.changelog-div:hover {
    transform: scale(1.01);
}

#changelog-empty {
    align-self: center;
    padding: 1rem;
}

.changelog-div-content {
    padding: 0.5rem 0 0 1rem;
}
