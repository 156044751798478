.notification-box {
    width: 24rem;
    height: 6rem;
    padding: 1rem 2rem;
    box-sizing: border-box;
    background-color: #192129;
    border: 2px solid black;
    border-radius: 1rem;
    position: absolute;
    top: 2rem;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    /* transform: translate(-50%, 0); */
    z-index: 40;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    transition: top 1s linear;
}

.notification-box-close-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.notification-box-timer {
    /* width: 20rem; */
    height: 0.25rem;
    box-sizing: border-box;
    border-radius: 0.125rem;
    align-self: flex-start;
    transition: width 1s linear;
}
