#page-not-found-wrapper {
    width: 100%;
    height: 90vh;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#page-not-found-wrapper > h2 {
    font-size: 4rem;
}

#page-not-found-wrapper > p {
    font-size: 1rem;
}
