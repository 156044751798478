#journal-front-page-menu-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: rgba(14, 22, 31, 1);
    overflow-y: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

#journal-front-page-menu-content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

/* JOURNAL COMPONENTS SHARED STYLES */

.journal-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.content-wrapper-header {
    width: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    background-color: rgba(14, 22, 31, 1);
    border-bottom: 1px solid black;
    align-self: center;
    text-align: center;
    position: sticky;
    top: 0rem; /* This is needed to apply the sticky positioning style */
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.content-wrapper-header > h2 {
    font-size: 2rem;
}

.content-wrapper-header > h3 {
    font-size: 1.5rem;
}
