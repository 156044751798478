.user-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.user-form-inputs {
    display: grid;
    grid-area: form;
    grid-template-areas:
        "username password"
        "button button";
    gap: 0 0.5rem;
    width: auto;
    height: auto;
    padding: 1rem;
    background-color: rgba(14, 22, 31, 1);
    border: 1px solid white;
    border-radius: 1rem;
}

.user-form-inputs > label {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
}

#login-name {
    grid-area: username;
}

#login-password {
    grid-area: password;
}

#sign-in-button {
    grid-area: button;
    align-self: center;
}

/* REGISTER FORM STYLES */
#user-form-inputs-register {
    grid-template-areas:
        "username email"
        "password button";
}

#register-name {
    grid-area: username;
}

#register-password {
    grid-area: password;
}

#register-email {
    grid-area: email;
}

#create-account-button {
    grid-area: button;
    align-self: center;
}

.user-form-buttons {
    grid-area: buttons;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.user-form-button {
    width: 8rem;
    height: fit-content;
    margin: 0 auto;
    align-self: flex-end;
}
