.player-meter-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2rem;
    /* padding: 0 3%; */
    box-sizing: border-box;
    color: rgb(255, 255, 255);
}

.player-meter-bar-class {
    /* position: absolute; */
}

.player-meter-bar-class-icon {
    width: 2rem;
    height: 2rem;
}

.player-meter-bar-class-icon > div {
    width: inherit;
    height: inherit;
    background-size: cover;
    filter: brightness(0) invert(1);
}

.player-meter-bar-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 68%;
    position: absolute;
    /* padding-left: 1rem; */
    gap: 0.5rem;
}

.player-meter-bar-numbers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    width: 32%;
    position: absolute;
    right: 0;
    padding-right: 1rem;
}

/* All class colors are the class colors from WoW, with a few exceptions as some did not fit. A comment has been left if the D&D class name did not match the WoW class name */

.artificer {
    /* This is the Evoker class color from WoW */
    background-color: rgb(51, 147, 127);
}

.barbarian {
    /* This is the Death Knight class color from WoW */
    background-color: rgb(196, 30, 58);
}

.bard {
    /* Improvised class color for bard, subject to change */
    background-color: rgb(143, 18, 112);
}

.blood-hunter {
    /* This is the Demon Hunter class color from WoW */
    background-color: rgb(163, 48, 201);
}

.cleric {
    /* This was the Priest class color from WoW, had to change to a darker color due to it being too bright making the text unreadable */
    background-color: rgb(160, 160, 160);
}

.druid {
    background-color: rgb(255, 124, 10);
}

.fighter {
    /* This is the Warrior class color from WoW */
    background-color: rgb(198, 155, 109);
}

.monk {
    /* This was the Monk class from WoW, had to change to a slightly darker variant due to the orignal being too bright */
    /* Original Color */
    /* background-color: rgb(0, 255, 152); */
    /* Updated Color */
    background-color: rgb(18, 114, 76);
}

.paladin {
    background-color: rgb(244, 140, 186);
}

.ranger {
    /* This is the Hunter class color from WoW */
    background-color: rgb(170, 211, 114);
}

.rogue {
    background-color: rgb(184, 176, 63);
}

.sorcerer {
    /* This is the Sorcerer class color from WoW */
    background-color: rgb(0, 112, 221);
}

.warlock {
    background-color: rgb(135, 136, 238);
}

.wizard {
    /* This is the Mage class color from WoW */
    background-color: rgb(63, 199, 235);
}

.unknown {
    /* No class color provided here as there no class to relate it to - just picked a color that wasnt used too often */
    background-color: rgb(135, 206, 235);
}
