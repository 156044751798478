#header-bar-wrapper {
    height: 10vh;
    padding: 0 2rem;
    background-color: rgba(14, 22, 31, 1);
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    box-sizing: border-box;
}

/* TITLE STYLES */
#header-bar-title-wrapper {
    font-size: 3rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

#header-bar-logo {
    height: 5rem;
    width: auto;
}

/* USER BAR STYLES */
#header-bar-user-wrapper {
    font-size: 1.2rem;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
}

/* #header-bar-user-greeting {
} */

#header-bar-user-buttons {
    width: auto;
    display: flex;
    /* gap: 1rem; */
}

#header-bar-user-buttons :not(:last-child) {
    border-right: 1px solid white;
    padding: 0 0.7rem;
}

#header-bar-user-buttons :last-child {
    /* border-right: 1px solid white; */
    padding: 0 0 0 0.7rem;
}

.header-bar-user-buttons-div {
    cursor: pointer;
    display: flex;
}

.header-bar-user-buttons-div:hover {
    text-decoration: underline;
}

.header-bar-user-icon {
    transition: color 0.2s linear;
    cursor: pointer;
}
