#create-campaign-form {
    width: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#create-campaign-form > legend {
    margin-bottom: 1rem;
}

#create-campaign-form > label {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    margin: 0.5rem 0;
    width: 100%;
}

#create-campaign-form > label > textarea {
    resize: vertical;
}
