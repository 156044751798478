#journal-front-page-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
    margin: auto;
}

#journal-front-page-wrapper > h2 {
    font-size: 3rem;
}

#journal-front-page-wrapper > p {
    font-size: 1rem;
}
