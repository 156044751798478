.dashboard-banner-campaign-settings-wrapper {
    border-top: 0.2rem solid black;
    grid-area: settings; /* This sets the grid area for the parent component - parent component styles located within Banner.css */
    padding: 1rem;
    display: grid;
    align-items: center;
    grid-template-areas:
        "title title"
        "data users"
        ". invite";
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 1fr auto 1fr;
    gap: 0.5rem;
}

.dashboard-settings-admin-icons {
    position: absolute;
    right: 1rem;
    width: 3rem;
    height: 1.5rem;
    display: flex;
    gap: 1rem;
}

/* CAMPAIGN SETTINGS TITLE STYLES START */
.dashboard-banner-campaign-settings-title-wrapper {
    grid-area: title;
    justify-self: center;
}

.dashboard-banner-campaign-settings-title-wrapper > h2 {
    font-size: 1.5rem;
}

/* CAMPAIGN SETTINGS TITLE STYLES END */

/* CAMPAIGN SETTINGS NAME/DESCRIPTION STYLES START */
.dashboard-banner-campaign-settings-details-wrapper > form {
    grid-area: data;
    height: -webkit-fill-available;
    display: grid;
    grid-template-areas:
        "name"
        "description"
        "update";
    grid-template-columns: 5fr;
    grid-template-rows: auto auto auto;
    gap: 0.5rem;
}

.dashboard-banner-campaign-settings-details-wrapper > form > div {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.dashboard-banner-campaign-settings-details-wrapper > form > div > label {
    font-size: 1.2rem;
}

.dashboard-banner-campaign-settings-details-wrapper > form > div > textarea {
    resize: vertical;
}

.dashboard-banner-campaign-settings-details-name-wrapper {
    grid-area: name;
}

.dashboard-banner-campaign-settings-details-description-wrapper {
    grid-area: description;
}

.dashboard-banner-campaign-settings-details-button-wrapper {
    grid-area: update;
    align-self: center;
    justify-self: center;
}

/* CAMPAIGN SETTINGS NAME/DESCRIPTION STYLES END */

/* CAMPAIGN SETTINGS USERS STYLES START */
.dashboard-banner-campaign-settings-users-wrapper {
    color: yellow;
    grid-area: users;
}

/* CAMPAIGN SETTINGS USERS STYLES END */

/* CAMPAIGN SETTINGS INVITE STYLES START */
.dashboard-banner-campaign-settings-invite-code-wrapper {
    color: pink;
    grid-area: invite;
    justify-self: center;
}

/* CAMPAIGN SETTINGS INVITE STYLES END */
