#video-background-wrapper {
    width: 100vw;
    position: absolute;
    height: inherit;
    overflow: hidden;
}

#login-wrapper {
    height: 90vh;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
}

#login-wrapper > h1,
#login-wrapper > h2 {
    align-self: center;
}

.login-response-message {
    margin: auto;
}
#login-screen-wrapper {
    display: grid;
    grid-template-areas:
        "header header"
        "subheader login";
    grid-template-rows: 1fr 5fr;
    grid-template-columns: 5fr 8fr;
    position: absolute;
    background-color: rgba(14, 22, 31, 1);
    box-sizing: border-box;
    width: 60rem;
    height: 50vh;
    padding: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 2rem;
}

/* HEADER WRAPPER STYLES */
#login-screen-header-wrapper {
    grid-area: header;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#login-screen-header-wrapper > h1 {
    font-size: 4rem;
}

/* SUBHEADER STYLES */
#login-screen-subheader-wrapper {
    grid-area: subheader;
    padding: 0.5rem;
    border-right: 1px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding-bottom: 5rem;
}

#login-screen-subheader-wrapper > h2 {
    font-size: 2rem;
}

#login-screen-subheader-wrapper > h3 {
    font-size: 1.3rem;
    text-align: center;
}

/* LOGIN WRAPPER STYLES */
#login-screen-content-display-wrapper {
    grid-area: login;
    display: flex;
    align-items: center;
    justify-content: center;
}
