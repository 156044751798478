.dashboard-banner {
    height: auto;
    min-height: 8rem;
    margin: 1rem;
    background-color: rgba(14, 22, 31, 1);
    border: 4px solid black;
    border-radius: 1rem;
    display: grid;
    grid-template-areas:
        "text image"
        "settings settings";
    grid-template-columns: 2fr 3fr;
    grid-template-rows: auto auto;
    transition: transform 0.1s linear;
}

.dashboard-banner-text {
    min-height: 8rem;
    grid-area: text;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
}

.dashboard-banner-text > h2 {
    font-size: 1.5rem;
    text-decoration: underline;
}

.dashboard-banner-text > p {
    flex-grow: 1;
}

.dashboard-banner-admin-icons {
    /* color: white; */
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 3rem;
    height: 1rem;
    display: flex;
    gap: 1rem;
}
.dashboard-banner-not-admin-icons {
    /* color: white; */
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    gap: 1rem;
}

.banner-fa-icon {
    cursor: pointer;
}

.dashboard-banner-image {
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.2rem;
    border: 2px solid black;
    border-radius: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
}
